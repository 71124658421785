<template>
	<div class="tn-list">
		<ol v-if="ordered">
			<slot />
		</ol>
		<ul v-else>
			<!-- @slot Slot for receiving a list of TnListItem subcomponents -->
			<slot />
		</ul>
	</div>
</template>

<script>
import { defineComponent } from "vue";

import sizes from "./definitions/sizes";

/**
 * TnList is a customizable list-component with options for ordered and unordered list. With or without subtext and with optional custom icons
 * @displayName TnList
 */
export default defineComponent({
	name: "TnList",

	provide() {
		return {
			ordered: this.ordered,
			size: this.size,
		};
	},

	props: {
		/**
		 * Enable to use ordered list with numbers instead of unordered list.
		 */
		ordered: {
			type: Boolean,
			default: false,
		},
		/**
		 * This will determine the size of the icons and fonts of the list.
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
	},
});
</script>
