<template>
	<li :class="['tn-list--item', size, type]">
		<div
			v-if="icon && !ordered"
			:class="['tn-list--icon', size]"
		>
			<TnIcon
				:name="icon"
				class="icon"
				:size="size"
			/>
		</div>
		<div v-else-if="type && !ordered">
			<TnIcon
				:name="unOrderedIcon"
				class="icon"
				:size="size"
			/>
		</div>
		<div
			v-else-if="ordered && orderedIcon !== ''"
			class="ordered"
			:class="[orderedIcon, size]"
		>
			<span class="number">{{ itemNumber }}</span>
		</div>
		<div
			v-else
			:class="['ordered', 'tn-list--number', size]"
		>
			<span :class="['number', size]">{{ itemNumber }}.</span>
		</div>
		<div class="tn-list--content">
			<div class="list-titles">
				<TnParagraph
					v-if="title"
					:size="size"
					:bold="!!title"
				>
					{{ title }}
				</TnParagraph>
				<!-- @slot Slot for custom list heading markup. -->
				<slot
					v-else
					name="title"
				/>
			</div>
			<div class="list--slot">
				<TnParagraph
					v-if="text"
					class="tn-list--body"
					:size="size"
					>{{ text }}</TnParagraph
				>
				<!-- @slot Default slot for custom list body markup. -->
				<slot v-else />
			</div>
		</div>
	</li>
</template>

<script>
import { defineComponent } from "vue";

import types from "./definitions/type";

export default defineComponent({
	name: "TnListItem",
	inject: ["ordered", "size"],

	props: {
		/**
		 * This is the heading or title of the list item.
		 */
		title: {
			type: String,
			default: null,
		},
		/**
		 * This is the body text of the list item.
		 */
		text: {
			type: String,
			default: null,
		},
		/**
		 * Name of the icon (from the TnIcon-library) to display on the list. Can also be an array of icon string names.
		 */
		icon: {
			type: String,
			default: null,
		},
		/**
		 * This will display if ordered list is toggled. Must be binded with a value of i+1 to display the right number sequence.
		 */
		itemNumber: {
			type: Number,
		},
		type: {
			type: String,
			default: "bullet",
			validator: function (value) {
				return types.includes(value.toLowerCase());
			},
		},
	},

	computed: {
		unOrderedIcon() {
			return this.type.toLowerCase() === "checkmark" ? "success" : this.type;
		},
		orderedIcon() {
			return this.type.toLowerCase() === "numbered-outline" || this.type.toLowerCase() === "numbered-filled"
				? this.type
				: "";
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/scss/placeholders";
@use "@/assets/scss/variables" as variables;

.tn-list--item {
	font-family: variables.$font-family-telenor-ui;
	list-style: none;
	display: flex;
	align-content: center;

	&.s {
		margin-bottom: variables.$spacing-s;

		h3 {
			@extend %font-text-s;
		}

		.ordered.numbered-filled,
		.ordered.numbered-outline {
			width: 16px;
			height: 16px;
			margin-right: variables.$spacing-s;

			span {
				@extend %font-text-s;
			}
		}
	}

	&.m {
		margin-bottom: variables.$spacing-s;

		h3 {
			@extend %font-text-m;
		}

		.ordered.numbered-filled,
		.ordered.numbered-outline {
			width: 24px;
			height: 24px;
			margin-right: variables.$spacing-s;

			span {
				@extend %font-text-m;
			}
		}
	}

	&.l {
		margin-bottom: variables.$spacing-s;

		h3 {
			@extend %font-text-l;
		}

		.ordered.numbered-filled,
		.ordered.numbered-outline {
			width: 28px;
			height: 28px;
			margin-right: variables.$spacing-s;

			span {
				@extend %font-text-l;
			}
		}
	}

	.tn-list--number {
		&.s {
			margin-right: variables.$spacing-s;
			transform: translateY(1px);
		}

		&.m {
			margin-right: variables.$spacing-m;
			transform: translateY(2px);
		}

		&.l {
			margin-right: variables.$spacing-l;
			transform: translateY(2px);
		}
	}

	.tn-list--icon {
		&.s {
			margin-right: variables.$spacing-s;
			transform: translateY(4px);
		}

		&.m {
			margin-right: variables.$spacing-m;
		}

		&.l {
			margin-right: variables.$spacing-l;
		}

		.number {
			font-family: variables.$font-family-telenor-ui;

			&.s {
				font-size: 16px;
				line-height: 24px;
			}

			&.m {
				font-size: 18px;
				line-height: 24px;
			}

			&.l {
				font-size: 24px;
				line-height: 32px;
			}
		}
	}
}

.tn-list--body {
	color: variables.$color-neutrals-600-shade;
}

.ordered.numbered-filled,
.ordered.numbered-outline {
	border-radius: 100%;
	display: flex;
	place-content: center center;
	border: solid thin black;
}

.ordered.numbered-filled {
	background-color: black;
	color: white;
}
</style>
